import React from "react"
import ComponentDescription from "../components/component-description/componentDescription"
import BorderTitle from "../components/border-title/borderTitle"
import ReadMore from "../components/read-more/readMore"

import SEO from "../components/seo"
import Banner from "../components/banner/banner"
import ComponentCard from "../components/component-card/componentCard"
import PageBody from "../components/page-body/pageBody"
import bg from "../images/banners/home.jpg"
import { componentsYearTwo } from "../models/programComponents"
import { homePage } from "../utils/text"

import "./index.scss"

const IndexPage = props => (
  <>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div className="index-page">
      <Banner
        src={bg}
        title={homePage.banner.title}
        subTitle={homePage.banner.subtitle}
        height="40rem"
      />
      <PageBody className="page">
        {/* <div className="card-components">
          {homePage.cardComponents.map(card => (
            <ComponentCard
              className="card-component"
              key={card.title}
              icon={card.icon}
              title={card.title}
              description={card.description}
              color={card.color}
              button={card.button}
              link={card.link}
            />
          ))}
        </div> */}
        <div className="about-program">
          <BorderTitle className="title">{homePage.about.title}</BorderTitle>
          <p className="quote">{homePage.about.quote}</p>
          <p>{homePage.about.body}</p>
          <ReadMore to="/about/the-program" />
        </div>
        <div className="eight-program-components">
          <BorderTitle className="title">
            THE FIVE PROGRAM COMPONENTS
          </BorderTitle>
          <div className="component-descriptions">
            {componentsYearTwo.map(component => (
              <ComponentDescription
                key={component.title}
                className="component-description"
                icon={component.icon}
                pageName={component.pageName}
                componentsDir="components-year-two"
                title={component.title}
                description={component.description}
                readMore
              />
            ))}
          </div>
        </div>
      </PageBody>
    </div>
  </>
)

export default IndexPage
